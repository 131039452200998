import { Helmet } from "react-helmet";
// import homePic from "../images/Homepage.jpg";
import Cheers from "../images/Cheers2.jpg";
import outsideDeck from "../images/Top Level Lounge.jpg";
import mainDeck from "../images/Main Deck.jpg";
import midDeck from "../images/Mid Level Lounge.jpg";
import vessel from "../images/Vessel.jpg";
import Carousel from "react-bootstrap/Carousel";
import ReviewSlide from "../components/ReviewSlide";
import whale from "../images/Whale Watch.jpg";
import fireworks from "../images/Fireworks.jpg";
import { Link } from "react-router-dom";
import Drinks from "../components/Drinks";
import JoinUs from "../components/JoinUs";
import homeVideo from "../images/home.mp4";

function Home() {
  return (
    <>
      <Helmet>
        <title>
          Majestic by Atlantis Cruises | Waikiki Sunset Cocktail Cruise
        </title>
      </Helmet>
      {/* <img src={homePic} alt="Home" className="w-100 vh-100 fullScreen" /> */}
      <div className="container mw-100">
        <div className="row">
          <video
            src={homeVideo}
            autoPlay
            loop
            muted
            playsInline
            className="p-0 homeVidSize"
          />
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 m-0 p-0 order-2 order-lg-1">
            <img src={Cheers} alt="cheers" className="w-100" />
          </div>
          <div
            className="col-12 col-lg-6 order-1 order-lg-2 align-items-center d-flex text-center py-5 py-lg-0 justify-content-center"
            style={{ backgroundColor: "#415F84" }}
          >
            <div className="col-12 col-sm-10 col-xxl-8 text-light">
              <h2 className="fw-bold mb-4">
                HAWAII'S ONLY FULL SERVICE BAR AND COCKTAIL LOUNGE ON THE SEA
              </h2>
              <p>
                Step out onto Majestic's open-air decks and take the opportunity
                to simply enjoy Hawaii's beautiful sunsets and skylines. The
                Majestic features two decks that offer the space to take in
                unobscured panoramic views and lovely breezes.
              </p>
              <p className="mb-0">
                Enjoy a complimentary welcome drink as you board. Join us for
                cocktails in the only full service, open seating bar and lounge
                sailing Oahu's waters!
              </p>
            </div>
          </div>
        </div>
        <div className="row bg-light justify-content-center text-center">
          <div className="col-11 col-md-10 py-5">
            <div style={{ color: "#03286B" }}>
              <h2 className="fw-bolder mb-4">
                WAIKIKI SUNSET COCKTAIL CRUISE TIMES
              </h2>
              <p className="fs-5">
                <b>Spring/Summer:</b>
                <br />
                February 16 - September 15
                <br />
                6:00 pm - 7:30 pm
              </p>
              <p className="mb-0 fs-5">
                <b>Fall/Winter:</b>
                <br />
                September 16 - February 15
                <br />
                5:15 pm - 6:45 pm
              </p>
            </div>
          </div>
        </div>
        <div className="row decksBG text-center text-light">
          <div className="col-12 col-md-4 p-0">
            <img src={outsideDeck} alt="top deck" className="w-100" />
            <div className="py-5">
              <h2>TOP LEVEL</h2>
              <div
                className="text-center position-relative my-4"
                style={{
                  marginLeft: "50%",
                  left: "-50px",
                  width: "100px",
                  height: "8px",
                  backgroundColor: "#415F84",
                }}
              ></div>
              <div className="col-10 col-xl-8 mx-auto">
                <p className="mb-0">
                  Experience Hawaii's warm, salty, sea breezes and 360-degree
                  scenic views from our open-air deck providing an ideal spot to
                  see Waikiki from a whole new perspective.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 p-0">
            <img src={midDeck} alt="mid deck" className="w-100" />
            <div className="py-5">
              <h2>MID-LEVEL LOUNGE</h2>
              <div
                className="text-center position-relative my-4"
                style={{
                  marginLeft: "50%",
                  left: "-50px",
                  width: "100px",
                  height: "8px",
                  backgroundColor: "#415F84",
                }}
              ></div>
              <div className="col-10 col-xl-8 mx-auto">
                <p className="mb-0">
                  Join us for cocktails in the only full service, open seating
                  bar and lounge sailing Oahu's waters. Relax and enjoy live,
                  contemporary Hawaiian music.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 p-0">
            <img src={mainDeck} alt="main deck" className="w-100" />
            <div className="py-5">
              <h2>MAIN DECK</h2>
              <div
                className="text-center position-relative my-4"
                style={{
                  marginLeft: "50%",
                  left: "-50px",
                  width: "100px",
                  height: "8px",
                  backgroundColor: "#415F84",
                }}
              ></div>
              <div className="col-10 col-xl-8 mx-auto">
                <p className="mb-0">
                  Our ideal location for large parties with space for
                  customized, catered dining events. See our group events and
                  charters page if you would like to host a special Majestic
                  celebration!
                </p>
              </div>
            </div>
          </div>
        </div>
        <Drinks cocktail />
        <JoinUs
          text="yes"
          link="https://book.majestichawaii.com/details/204130/waikiki-sunset-cocktail-cruise/?promo=20sunsetwebsite"
        />
      </div>
      <img src={vessel} alt="vessel" className="w-100 p-0" />
      <div className="container mw-100">
        <div
          className="row bg-light text-center justify-content-center"
          style={{ color: "#03286B" }}
        >
          <div className="col-11 col-md-8 col-lg-6 py-5">
            <h2 className="fw-bold mb-4">VESSEL INFORMATION</h2>
            <p className="mb-0 fs-5">
              There is nothing quite like our 150' sleek, modern ship with three
              spacious levels to soak in the beauty of Waikiki's cerulean seas
              and spectacular coastline. Grab a handcrafted cocktail and get
              ready for Hawaii's magical sunset and sparkling city lights.
            </p>
          </div>
        </div>
        <div className="row bgDH py-5 justify-content-center text-center">
          <Carousel indicators={false} pause={false} interval={null}>
            <Carousel.Item>
              <ReviewSlide
                title="Beautiful experience"
                review="“Such an amazing, beautiful experience. The staff was very friendly and efficient. Our waiter
was amazing and kind. Provided free cocktail when boarding. All alcoholic beverages were
delicious. There was a singer playing beautiful Hawaiian songs, appreciate the music. Very
comfortable cruise. Overall great experience.”"
                person="Karina C."
              />
            </Carousel.Item>
            <Carousel.Item>
              <ReviewSlide
                title="Beautiful views, excellent customer service and live music"
                review="“We highly recommend this cruise. Staff were great. The views were gorgeous. We were very
lucky and got to see a breaching baby whale. The music was mellow and beautiful. The singer
played a guitar or ukulele and did perfect covers of IZ’s classics as well as some other popular
musicians.”"
                person="Ann W."
              />
            </Carousel.Item>
            <Carousel.Item>
              <ReviewSlide
                title="Excellent evening"
                review="“Had a great evening on the sunset cruise! Staff was amazing!!! Highly recommend!”"
                person="Kenneth B."
              />
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 m-0 p-0 position-relative">
            <Link to="/whalewatch">
              <img src={whale} alt="whale watch" className="w-100" />
              <div
                className="position-absolute top-0 start-0 ms-4 mt-4 w-75"
                style={{ color: "#03286B" }}
              >
                <p className="mb-0 fw-bold">LEARN MORE</p>
                <h4 className="fw-bold">WHALE WATCH CRUISE</h4>
              </div>
            </Link>
          </div>
          <div className="col-12 col-lg-6 m-0 p-0 position-relative">
            <Link to="/friday-fireworks-cocktail-cruise">
              <img src={fireworks} alt="fireworks" className="w-100" />
              <div className="position-absolute top-0 start-0 ms-4 mt-4 text-light">
                <p className="mb-0 fw-bold">LEARN MORE</p>
                <h4 className="fw-bold">
                  FRIDAY FIREWORKS
                  <br />& COCKTAIL CRUISE
                </h4>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
