import Button from "react-bootstrap/Button";

function JoinUs({ text, link }) {
  return (
    <div className="row bg-light py-5 justify-content-center">
      <div
        className="col-11 col-sm-7 col-md-6 col-lg-5 col-xxl-4"
        style={{ color: "#03286B" }}
      >
        <h2 className="fw-bold">JOIN US</h2>
        {text && (
          <p>
            Don't miss out! Join us for an evening of relaxation, great views,
            and unforgettable memories.
          </p>
        )}

        <p>
          <b>RETAIL RATES</b>
          <br />
          Adult (13+): $79
          <br />
          Child (3-12 yrs): $39.50
          <br />
          Kids 2 years and under ride free
        </p>
        {!text && (
          <p>
            <b>CRUISE TIME</b>
            <br />
            December 26 to March 31, Daily from 11:30am to 2pm.
          </p>
        )}

        <p className="mb-0">
          <b>DEPARTS FROM</b>
          <br />
          <a
            href="https://maps.app.goo.gl/Xj83FwDmy1DGEGP98"
            target="_blank"
            rel="noreferrer noopener"
          >
            Aloha Tower Marketplace, Pier 6<br />
            Located at 301 Aloha Tower Drive, Honolulu, HI 96813
          </a>
        </p>
      </div>
      <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 pt-4 pt-sm-0 d-flex align-items-center justify-content-center">
        <Button href={link} size="lg" className="booknow-cta text-nowrap">
          BOOK NOW
        </Button>
      </div>
    </div>
  );
}

export default JoinUs;
