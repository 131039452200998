import { Helmet } from "react-helmet";
import fireworksMain from "../images/Fireworks Main.jpg";
import ReviewSlide from "../components/ReviewSlide";
import Carousel from "react-bootstrap/Carousel";
import couple from "../images/Fireworks Couple.jpg";
import Drinks from "../components/Drinks";
import JoinUs from "../components/JoinUs";
import fireworks1 from "../images/fireworksCarousel/fireworks (1).jpg";
import fireworks2 from "../images/fireworksCarousel/fireworks (2).jpg";
import fireworks3 from "../images/fireworksCarousel/fireworks (3).jpg";
import fireworks4 from "../images/fireworksCarousel/fireworks (4).jpg";

function Fireworks() {
  return (
    <>
      <Helmet>
        <title>
          Majestic by Atlantis Cruises | Friday Fireworks & Cocktail Cruise
        </title>
      </Helmet>
      {/* <img
        src={fireworksMain}
        alt="Fireworks Main"
        className="w-100 vh-100 fullScreen"
      /> */}

      <Carousel indicators={true} interval={5000} fade pause="false">
        <Carousel.Item>
          <img
            src={fireworksMain}
            alt="Fireworks Main"
            className="homeVidSize w-100"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={fireworks1}
            alt="Fireworks 1"
            className="homeVidSize w-100"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={fireworks2}
            alt="Fireworks 2"
            className="homeVidSize w-100"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={fireworks3}
            alt="Fireworks 3"
            className="homeVidSize w-100"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={fireworks4}
            alt="Fireworks 4"
            className="homeVidSize w-100"
          />
        </Carousel.Item>
      </Carousel>

      <div className="container mw-100">
        <div className="row decksBG text-center justify-content-center">
          <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7 py-4 my-4 py-xl-5 my-xl-5 text-light">
            <h2 className="fw-bold mb-4">FRIDAY FIREWORKS & COCKTAIL CRUISE</h2>
            <p className="mb-0">
              The best seat in the house for Friday night fireworks is aboard
              Majestic. Experience a memorable evening with fireworks and
              cocktails on the sea aboard Majestic by Atlantis Cruises. Cruise
              in comfort aboard our sleek, modern ship as Waikiki's skies soften
              to peaceful twilight-colored hues. Grab a hand-crafted cocktail
              from our full-service bar and cocktail lounge on the sea while you
              soak in breathtaking views from our air-conditioned lounge. Don't
              miss our top-level deck which offers 360-degree, open-air views of
              Waikiki's famous coastline as the city comes to life with its
              glimmering evening lights. Feel free to roam all three levels of
              our spacious ship to get a front row seat to Oahu's stunning
              fireworks show. See this vibrant display from a whole new
              perspective as the colorful lights reflect off the sea. Kick back
              and enjoy our live entertainment too.
            </p>
          </div>
        </div>
        <Drinks cocktail />
        <JoinUs
          text="yes"
          link="https://book.majestichawaii.com/details/205347/friday-fireworks-cocktail-cruise"
        />
        <div className="row bgDH py-5 justify-content-center text-center">
          <Carousel indicators={false} pause={false} interval={null}>
            <Carousel.Item>
              <ReviewSlide
                title="Amazing!"
                review="“The whole experience was magical. The staff were very friendly and helpful. The
entertainment was beautiful. Thank you for providing a beautiful environment for one of the
best nights of my life!”"
                person="Bryan T."
              />
            </Carousel.Item>
            <Carousel.Item>
              <ReviewSlide
                title="Entertainment + breathtaking views"
                review="“You can’t beat fireworks from the sea! The staff was very attentive and kind. The
entertainment was lovely. Kayla is such a talented hula dancer. The way she conveys the
emotion of the music through the movements of her body and facial expressions is surreal. Our
whole family was so captivated and hypnotized by her dancing. Hula is truly a beautiful cultural
dance. We will highly recommend this company to our friends and family.”"
                person="Marysol R."
              />
            </Carousel.Item>
            <Carousel.Item>
              <ReviewSlide
                title="Fun & Fireworks"
                review="“Enjoyed a wonderful evening celebrating our 50 th wedding anniversary. Your staff was very
kind and acknowledged our celebration. We loved the fireworks but thought your
entertainment was exceptional. The hulas that were performed were beautiful. The music
played and the vocals as well were just perfect. We also purchased a photo to remember our
very enjoyable evening.”"
                person="Linda S."
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <img src={couple} alt="couple" className="w-100 p-0" />
    </>
  );
}

export default Fireworks;
