import { useEffect } from "react";
import { Helmet } from "react-helmet";

function Kamaaina() {
  useEffect(() => {
    window.location.replace("https://atlantisadventures.com/kamaaina");
  }, []);
  return (
    <Helmet>
      <title>Majestic by Atlantis Cruises | Kama'aina Discounts</title>
    </Helmet>
  );
}

export default Kamaaina;
