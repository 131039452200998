import { Helmet } from "react-helmet";
// import whaleMain from "../images/Whale Main.jpg";
import circle1 from "../images/circleImg (1).png";
import circle2 from "../images/circleImg (2).png";
import circle3 from "../images/circleImg (3).png";
import boatWhale from "../images/whaleBanner.jpg";
import Drinks from "../components/Drinks";
import JoinUs from "../components/JoinUs";
import Tails from "../images/Tails.jpg";
import ReviewSlide from "../components/ReviewSlide";
import Carousel from "react-bootstrap/Carousel";
import homeVideo from "../images/whale.mp4";

function Whale() {
  return (
    <>
      <Helmet>
        <title>Majestic by Atlantis Cruises | Whale Watch Cruise</title>
      </Helmet>
      {/* <img
        src={whaleMain}
        alt="Whale Main"
        className="w-100 vh-100 fullScreen"
      /> */}
      <div className="container mw-100">
        <div className="row">
          <video
            src={homeVideo}
            autoPlay
            loop
            muted
            playsInline
            className="p-0 homeVidSize"
          />
        </div>
        <div className="row bg-light text-center justify-content-center">
          <div
            className="col-12 col-md-10 col-lg-9 col-xl-7 py-5"
            style={{ color: "#03286B" }}
          >
            <h2 className="fw-bold mb-4">
              THE MAJESTIC WHALE WATCHING EXPERIENCE
            </h2>
            <p>
              Each year, humpback whales return to the Hawaiian Islands from
              frigid Alaskan waters to breed, calve, and nurse their young. Come
              witness this amazing event for yourself aboard Majestic!
            </p>
            <p className="mb-0">
              Our sleek, modern, 150-foot vessel offers multiple outdoor viewing
              decks to watch these fascinating creatures. Or relax with your
              family in our comfortable, air-conditioned lounge stocked with a
              full-service cocktail bar on the sea.
            </p>
          </div>
        </div>
        <div className="row whaleLevelsBG pt-4 pb-4 text-light">
          <div className="pt-5 pb-5">
            <div className="row center">
              <h2 className="fw-bold mb-4">CHOOSE YOUR SPACE</h2>
              <p className="pb-5">
                The Majestic offers three different levels for seeing humpback
                whales. The top level open-air deck offers 360-degree views,
                ideal for spotting exciting whale activity and other marine
                life. The lower air conditioned cabins are comfortable, modern,
                and sleek in design. The ship is also lined with large panoramic
                windows to provide wonderful whale sighting opportunities.
              </p>
            </div>
            <div className="row justify-content-evenly center">
              <div className="col-10 col-md-3">
                <img src={circle1} className="full" alt="circle 1" />
                <h2
                  className="
              pt-4 fw-bold mb-0 pb-5 pb-md-0"
                >
                  TOP LEVEL
                </h2>
              </div>
              <div className="col-10 col-md-3">
                <img src={circle2} className="full" alt="circle 2" />
                <h2
                  className="
              pt-4 fw-bold mb-0 pb-5 pb-md-0"
                >
                  MID-LEVEL LOUNGE
                </h2>
              </div>
              <div className="col-10 col-md-3">
                <img src={circle3} className="full" alt="circle 3" />
                <h2
                  className="
              pt-4 fw-bold mb-0"
                >
                  MAIN DECK
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={boatWhale} alt="whale jumping" className="w-100 p-0" />
      <div className="container mw-100">
        <div
          className="row justify-content-center text-center text-light py-5"
          style={{ backgroundColor: "#8C9BAE" }}
        >
          <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
            <p className="fw-bold fs-3 mb-0 text-uppercase">
              Our Naturalist onboard shares insightful information and
              educational commentary on the humpback whale's behavior and
              biology.
            </p>
          </div>
        </div>
        <Drinks whale />
        <JoinUs link="https://book.majestichawaii.com/details/154748/whale-watch-cruise-in-waikiki" />
      </div>
      <img src={Tails} alt="Tails" className="w-100 p-0" />
      <div className="container mw-100">
        <div className="row justify-content-center py-5 bg-light">
          <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 text-center">
            <div style={{ border: "6px solid #6f839c", padding: "20px" }}>
              <h2 className="fw-bold" style={{ color: "#6f839c" }}>
                WHALE WATCH GUARANTEE:
              </h2>
              <p className="fs-4 fw-bold mb-0" style={{ color: "#6f839c" }}>
                If our Naturalist does not sight a whale during the cruise,
                guests are given a complimentary return whale watch cruise.
              </p>
            </div>
          </div>
        </div>
        <div className="row bgDH py-5 justify-content-center text-center">
          <Carousel indicators={false} pause={false} interval={null}>
            <Carousel.Item>
              <ReviewSlide
                title="We had a great time!"
                review="“We really enjoyed our time on the boat. There was plenty of room and shade. The service was
great with a very friendly and knowledgeable staff. We saw the whales towards the end of the
cruise. Meanwhile, we had drinks and snacks while we enjoyed the music, the information and
the views of Waikiki and Diamond Head. It was a beautiful day with just a little bit of wind. I
highly recommend the Majestic with Atlantis Adventures if you are NOT looking for a party boat
with uncomfortable seats and no shade. The Majestic was perfect!”"
                person="Erika M."
              />
            </Carousel.Item>
            <Carousel.Item>
              <ReviewSlide
                title="Saw an incredible number of whales"
                review="“This was the best whale watching experience we have ever had. The boat was large enough so
that you would not experience the motion sickness that is possible on a smaller boat. You could
easily move from one part of the boat to another to get the best view of the whales. The staff
was attentive. Drinks and food were readily available if you wanted. The naturalist was very
informative and directed your attention to the whales as they were spotted. We saw many
humpback whales, usually in pairs, a mother and baby. We saw them spout, break the surface,
show us their tails, and on one occasion, fully breach out of the water. What an incredible
experience. Our whale watching tour on the Majestic exceeded our expectations.”"
                person="Thomas H."
              />
            </Carousel.Item>
            <Carousel.Item>
              <ReviewSlide
                title="Whale watching cruise-best ever!"
                review="“Absolutely beautiful cruise. Great time. Very helpful and informative crew. Wife and I highly
recommend to anyone!”"
                person="Harold C."
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default Whale;
