import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Error() {
  return (
    <>
      <Helmet>
        <title>Majestic by Atlantis Cruises | 404</title>
      </Helmet>
      <div className="container mw-100">
        <div className="row vh-100 justify-content-center bg-dark align-items-center">
          <div className="col-10 col-lg-6">
            <h1
              className="text-light pb-20 italicFont"
              style={{ fontSize: "calc(1.375rem + 1.5vw)" }}
            >
              Page Not Found!
            </h1>
            <Button variant="light" size="lg" as={Link} to="/">
              Home
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Error;
