import React from "react";

function DrinkMenu() {
  return (
    <div className="container bg-dark mw-100" style={{ paddingTop: "185px" }}>
      <div className="row justify-content-center pb-5">
        <h2 className="fw-bold text-center mb-5 text-light">
          DRINKS AVAILABLE FOR PURCHASE
        </h2>
        <div className="col-12 col-md-6 col-xxl-5 d-flex justify-content-start justify-content-md-center">
          <div className="text-light">
            <h6>SIGNATURE COCKTAILS</h6>
            <p>
              Original cocktails created and carefully crafted by our talented
              bartenders.
            </p>
            <p>
              Neal's “Sweet Escape”
              <br />
              Grey Goose Vodka, Simple Syrup, Lime Juice, Fresh Strawberry,
              &amp; a Splash of Soda Water.
            </p>
            <p>
              Tara's “Shaka”
              <br />
              Lemon drop inspired martini that's both delicious and beautiful.
            </p>
            <p>
              Justin's “Sweet Wahine”
              <br />
              Grey Goose Vodka, Grand Marnier, Cranberry Juice, Grenadine, &amp;
              Orange Juice.
            </p>
            <p>
              Ruyu's “Pineapple Sunrise”
              <br />
              Tanqueray Gin, Pineapple Juice, Lemon Juice, Grenadine, &amp;
              Pineapple Garnish.
            </p>
            <h6 className="pt-4">MAJESTIC COCKTAILS</h6>
            <p>
              Majestic Mele
              <br />
              Soho Lychee Liqueur, Vodka, Lemonade, &amp; a Splash of Cranberry
              Juice.
            </p>
            <p>
              Sunset Beach
              <br />
              Coconut Rum, Raspberry Liqueur, Orange, Pineapple &amp; Cranberry
              Juices.
            </p>
            <p>
              Diamond Head Dream
              <br />
              Melon Liqueur, Vodka, Pineapple Juice &amp; a Splash Of Sprite.
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 col-xxl-3 d-flex justify-content-start justify-content-md-center">
          <div className="text-light">
            <h6 className="pt-4 pt-md-0">CLASSIC COCKTAILS</h6>
            <p>
              Martini
              <br />
              Whiskey Sour
              <br />
              Margarita
              <br />
              Daiquiri
              <br />
              Blue Hawaii
              <br />
              Hawaiian Mai Tai
            </p>
            <h6 className="pt-4 text-light">BEERS</h6>
            <strong>Maui Brewing</strong>
            <p>
              Bikini Blonde
              <br />
              Pineapple
              <br />
              Mana Wheat
              <br />
              Big Swell IPA
            </p>
            <strong>Domestics</strong>
            <p>Miller Lite</p>
            <strong>Imports</strong>
            <p>Heineken</p>
            <p>*Packaged snacks also available for purchase*</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DrinkMenu;
