import drinks from "../images/Drinks.jpg";
import Button from "react-bootstrap/Button";
import FancyBox from "./FancyBox";
import DrinkMenu from "../images/DrinkMenu.jpg";
import drinkGif from "../images/2CompDrinks.gif";

function Drinks({ whale, cocktail, comp }) {
  <FancyBox />;
  return (
    <div className="row">
      <div className="col-12 col-lg-6 m-0 p-0">
        {comp ? (
          <img src={drinkGif} alt="drinks" className="w-100" />
        ) : (
          <img src={drinks} alt="drinks" className="w-100" />
        )}
      </div>
      <div
        className="col-12 col-lg-6 align-items-center d-flex text-center py-5 py-lg-0 justify-content-center text-light"
        style={{ backgroundColor: "#415F84" }}
      >
        <div className="col-12 col-sm-10 col-xxl-8">
          {whale && (
            <h2 className="pb-0 mb-0 fw-bold">
              CRUISE WITH US AND ENJOY HAWAII’S ONLY FULL-SERVICE BAR AND
              COCKTAIL LOUNGE ON THE SEA
            </h2>
          )}
          {comp && (
            <h2 className="pb-0 mb-0 fw-bold">
              CELEBRATE SUMMER AND ENJOY TWO COMPLIMENTARY WELCOME DRINKS IN
              JULY!
            </h2>
          )}
          {cocktail && (
            <h2 className="pb-0 mb-0 fw-bold">
              ENJOY ONE COMPLIMENTARY WELCOME DRINK AS YOU BOARD
            </h2>
          )}

          <div
            className="text-center position-relative my-5"
            style={{
              marginLeft: "50%",
              left: "-75px",
              width: "150px",
              height: "8px",
              backgroundColor: "#D9D9D9",
            }}
          ></div>
          <div className="fs-5">
            <p>Packaged food and snacks are also available for purchase.</p>
            <Button
              className="p-0 text-white fs-5"
              variant="link"
              data-fancybox
              data-src={DrinkMenu}
            >
              View Drink Menu
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Drinks;
