import collage from "../images/Groups People.png";
import collage2 from "../images/Collage.png";
import { Helmet } from "react-helmet";
import boat from "../images/Vessel.jpg";
import parties from "../images/parties.jpg";
import corporate from "../images/corporate.jpg";
import weddings from "../images/weddings.jpg";
import cheers from "../images/Cheers.jpg";
import Button from "react-bootstrap/Button";
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
import wholeBoat from "../images/Whole Boat.jpg";
import mainDeck from "../images/Deck Charter.jpg";
import dinnerCharter from "../images/Group Dinner.jpg";
import Carousel from "react-bootstrap/Carousel";
import groupSlide1 from "../images/groupsCarousel/groups (1).jpg";
import groupSlide2 from "../images/groupsCarousel/groups (2).jpg";
import groupSlide3 from "../images/groupsCarousel/groups (3).jpg";
import groupSlide4 from "../images/groupsCarousel/groups (4).jpg";
import groupSlide5 from "../images/groupsCarousel/groups (5).jpg";
import groupSlide6 from "../images/groupsCarousel/groups (6).jpg";

export default function Charters() {
  const form = useRef();

  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneType, setPhoneType] = useState("");
  const [company, setCompany] = useState("");
  const [event, setEvent] = useState("");
  const [date, setDate] = useState("");
  const [numPeople, setNumPeople] = useState("");
  const [numChildren, setNumChildren] = useState("");
  const [hear, setHear] = useState("");
  const [info, setInfo] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [errorFname, setErrorFname] = useState("formInput");
  const [errorEmail, setErrorEmail] = useState("formInput");
  const [errorPhone, setErrorPhone] = useState("formInput");
  const [errorPhoneType, setErrorPhoneType] = useState("formInput");
  const [errorCompany, setErrorCompany] = useState("formInput");
  const [errorEvent, setErrorEvent] = useState("formInput");
  const [errorDate, setErrorDate] = useState("formInput");
  const [errorNumPeople, setErrorNumPeople] = useState("formInput");
  const [errorNumChildren, setErrorNumChildren] = useState("formInput");
  const [errorStartTime, setErrorStartTime] = useState("formInput");
  const [errorEndTime, setErrorEndTime] = useState("formInput");
  const [errorHear, setErrorHear] = useState("formInput");

  const [waitMessage, setWaitMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (!fname) {
      setErrorFname("errorFormInput");
    } else setErrorFname("formInput");
    if (!email) {
      setErrorEmail("errorFormInput");
    } else setErrorEmail("formInput");
    if (!phone) {
      setErrorPhone("errorFormInput");
    } else setErrorPhone("formInput");
    if (!phoneType) {
      setErrorPhoneType("errorFormInput");
    } else setErrorPhoneType("formInput");
    if (!event) {
      setErrorEvent("errorFormInput");
    } else setErrorEvent("formInput");
    if (!company) {
      setErrorCompany("errorFormInput");
    } else setErrorCompany("formInput");
    if (!date) {
      setErrorDate("errorFormInput");
    } else setErrorDate("formInput");
    if (!startTime) {
      setErrorStartTime("errorFormInput");
    } else setErrorStartTime("formInput");
    if (!endTime) {
      setErrorEndTime("errorFormInput");
    } else setErrorEndTime("formInput");
    if (!numPeople) {
      setErrorNumPeople("errorFormInput");
    } else setErrorNumPeople("formInput");
    if (!numChildren) {
      setErrorNumChildren("errorFormInput");
    } else setErrorNumChildren("formInput");
    if (!hear) {
      setErrorHear("errorFormInput");
    } else setErrorHear("formInput");

    if (
      fname &&
      email &&
      phone &&
      phoneType &&
      company &&
      event &&
      date &&
      startTime &&
      endTime &&
      numPeople &&
      numChildren
    ) {
      setWaitMessage("Please wait...");

      emailjs
        .sendForm(
          "service_8kmbiu2",
          "template_ovsqzm8",
          form.current,
          "lIvuQcS29W-1LzLJc"
        )
        .then(
          function (response) {
            setFname("");
            setEmail("");
            setPhone("");
            setPhoneType("");
            setCompany("");
            setEvent("");
            setDate("");
            setStartTime("");
            setEndTime("");
            setNumPeople("");
            setNumChildren("");
            setInfo("");
            setWaitMessage("Your message has been sent.");
            console.log("SUCCESS!", response.status, response.text);
          },
          function (err) {
            console.log("FAILED...", err);
            setWaitMessage(
              "There was an error. Please reload the page and try again"
            );
          }
        );
    } else {
      setWaitMessage("Required field(s) missing. Please check form.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Majestic by Atlantis Cruises | Group Events & Charters</title>
      </Helmet>
      {/* <img
        src={main}
        alt="charters main"
        className="w-100 vh-100 fullScreen"
        style={{ objectPosition: "bottom center" }}
      /> */}

      <Carousel indicators={true} interval={5000} fade pause="false">
        <Carousel.Item>
          <img
            src={groupSlide1}
            alt="Charters 1"
            className="homeVidSize w-100"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={groupSlide2}
            alt="Charters 2"
            className="homeVidSize w-100"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={groupSlide3}
            alt="Charters 3"
            className="homeVidSize w-100"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={groupSlide4}
            alt="Charters 4"
            className="homeVidSize w-100"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={groupSlide5}
            alt="Charters 5"
            className="homeVidSize w-100"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={groupSlide6}
            alt="Charters 6"
            className="homeVidSize w-100"
          />
        </Carousel.Item>
      </Carousel>

      <div className="container mw-100">
        <div className="row justify-content-evenly text-center py-5 whaleLevelsBG">
          <div className="col-12 col-md-5 col-xxl-4 relative d-flex align-items-center">
            <div className="mx-auto">
              <h4 className="text-light fw-bold mb-4">
                UNFORGETTABLE EVENTS ON THE OCEAN
              </h4>
              <p className="text-light mb-4 mb-md-0">
                Whether it's a sunset cocktail cruise on the sea or a city
                lights cruise with a catered three-course meal, we've got you
                covered! Majestic provides the venue, crew and drinks. You book
                your favorite caterer, musician, DJ or other special vendor to
                help design an unforgettable event. Choose from semi-private
                seating, exclusive charters, or whole boat buyouts.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-5 col-xxl-4 d-flex align-items-center">
            <div className="mx-auto">
              <img src={collage} alt="collage" className="w-100" />
            </div>
          </div>
        </div>
      </div>
      <img src={boat} alt="boat front" className="w-100 p-0" />
      <div className="container mw-100">
        <div className="row justify-content-center text-center bg-light py-5">
          <div className="col-12 col-md-10 col-lg-8 col-xl-7">
            <h4 className="whaleBlueH mb-4">VESSEL INFORMATION</h4>
            <p className="whaleBlueP pb-4">
              The 150’ Majestic by Atlantis Cruises is Oahu’s newest yacht
              cruise experience. Our sleek and modern ship offers Waikiki’s only
              full-service bar and cocktail lounge on the sea. Soak-in the
              scenery of Diamond Head and emerald coastlines from our
              comfortable, air-conditioned cocktail lounge. Explore our top
              level, open-air deck with 360-degree sea views. Majestic’s main
              deck offers an ideal setting for a catered dining experience with
              large, panoramic windows so guests don’t miss out on the scenery.
              Some charter cruise offerings include a sunset or city lights
              cruise, fireworks cruise or brunch cruise.
            </p>
          </div>
          <div className="row justify-content-center text-center">
            <div className="col-11 col-md-4">
              <img src={weddings} alt="weddings" className="full" />
              <h5 className="whaleBlueH pt-20">Weddings</h5>
              <div className="col-12 col-sm-8 col-md-10 col-xl-9 mx-auto">
                <p className="whaleBlueP mb-4 mb-md-0">
                  Say "I do" on the sea! For the couple that dares to be
                  different, Majestic offers an amazing and fun event for your
                  friends and family.
                </p>
              </div>
            </div>
            <div className="col-11 col-md-4">
              <img src={corporate} alt="corporate" className="full" />
              <h5 className="whaleBlueH pt-20">Corporate Events</h5>
              <div className="col-12 col-sm-8 col-md-10 col-xl-9 mx-auto">
                <p className="whaleBlueP mb-4 mb-md-0">
                  Looking for a unique venue on the water for a corporate event?
                  Choose from three spacious levels or consider a Majestic
                  private charter.
                </p>
              </div>
            </div>
            <div className="col-11 col-md-4">
              <img src={parties} alt="parties" className="full" />
              <h5 className="whaleBlueH pt-20">Parties</h5>
              <div className="col-12 col-sm-8 col-md-10 col-xl-9 mx-auto">
                <p className="whaleBlueP mb-0">
                  Celebrate your next special event aboard Majestic! We love
                  hosting birthdays, baby luaus, engagements, bachelor &
                  bachelorette parties, retirement parties, and so much more.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row text-center justify-content-center"
          style={{ backgroundColor: "#415F84" }}
        >
          <div className="col-12 col-md-10 col-lg-8 col-xxl-7 py-5 text-light">
            <h3 className="fw-bold mb-4">EVENT SPACE AND CHARTERS</h3>
            <p className="mb-0">
              We offer customized event spaces and diverse options for
              chartering the entire boat for groups of all sizes. Whether you
              are planning a corporate event or celebrating a wedding or another
              type of milestone; the Majestic provides the perfect setting to
              indulge in breathtaking views to help you create unforgettable
              memories on the ocean. Reserve below or contact us for specialized
              information.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 m-0 p-0">
            <img src={wholeBoat} alt="whole boat" className="w-100" />
          </div>
          <div
            className="col-12 col-lg-6 align-items-center d-flex text-center px-4 px-sm-5 py-5 py-lg-0 justify-content-center"
            style={{ backgroundColor: "#e2e6eb" }}
          >
            <div className="col-12 col-sm-10 col-xxl-8 px-0 px-sm-3 px-md-5 px-lg-0">
              <h1 className="whaleBlueH mb-0 lh-1 majesticLarge">MAJESTIC</h1>
              <h5 className="whaleBlueH mb-1 text-nowrap wholeBoat">
                WHOLE BOAT PRIVATE CHARTER
              </h5>
              <h6 className="whaleBlueH wholeBoat2">(MORNING OR AFTERNOON)</h6>
              <div
                className="text-center position-relative my-5"
                style={{
                  marginLeft: "50%",
                  left: "-75px",
                  width: "150px",
                  height: "8px",
                  backgroundColor: "#7287aa",
                }}
              ></div>
              <p className="whaleBlueP">
                Experience the ultimate luxury of having an entire boat to
                yourself, complete with breathtaking ocean views, impeccable
                service, and unforgettable memories.
              </p>
              <p className="whaleBlueP">
                Pricing: $4000+ per hour
                <br />
                (Minimum 2 hour cruise)
              </p>
              <p className="mb-0 fw-bold whaleBlueP">
                Ready to book?
                <br />
                Please fill out the form below.
              </p>
            </div>
          </div>
        </div>
        <section id="book" />
        <div
          className="row bg-light text-center justify-content-evenly"
          style={{ paddingTop: "4rem" }}
        >
          <div className="col-12 col-md-6 col-lg-5 position-relative">
            <img src={mainDeck} alt="main deck" className="w-100" />
            <div
              className="position-absolute text-light px-3 fw-bold fs-5 top-0 start-50 translate-middle text-nowrap lh-1"
              style={{
                backgroundColor: "#8c9bae",
                border: "1px solid #7b8a9d",
                paddingTop: "10px",
                paddingBottom: "12px",
              }}
            >
              MAIN DECK FLOOR CHARTER
            </div>
            <p className="whaleBlueP mt-3 mb-4 col-12 col-sm-10 col-xxl-9 d-flex mx-auto">
              Semi-private main deck floor charter space accomodates up to 170
              passengers on the 1st level of the ship, yet guests can access all
              3 levels of the boat. Rates are for event space only. Outside
              caterers are welcome, or contact us for special food and beverage
              packages.
            </p>
            <div className="mobileShow" style={{ paddingBottom: "5rem" }}>
              <p className="whaleBlueP mb-4">Pricing: $5000+</p>
              <Button
                href="http://book.majestichawaii.com/details/340996/main-deck-floor-charter"
                size="lg"
                className="booknow-cta text-nowrap"
              >
                BOOK NOW
              </Button>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-5 position-relative">
            <img src={dinnerCharter} alt="main deck" className="w-100" />
            <div
              className="position-absolute text-light px-3 fw-bold fs-5 top-0 start-50 translate-middle text-nowrap lh-1"
              style={{
                backgroundColor: "#8c9bae",
                border: "1px solid #7b8a9d",
                paddingTop: "10px",
                paddingBottom: "12px",
              }}
            >
              GROUP DINNER CRUISE
            </div>
            <p className="whaleBlueP mt-3 mb-4 col-12 col-sm-10 col-xxl-9 d-flex mx-auto">
              Host an unforgettable dinner cruise aboard our spacious boat,
              perfect for groups of 30 to 80 people, and delight in breathtaking
              views of Waikiki as you create lasting memories.
            </p>
            <div className="mobileShow mb-5">
              <p className="whaleBlueP mb-4">Pricing: $108 per person</p>
              <Button
                href="http://book.majestichawaii.com/details/339208/group-dinner-cruise"
                size="lg"
                className="booknow-cta text-nowrap"
              >
                BOOK NOW
              </Button>
            </div>
          </div>
        </div>
        <div className="row pb-5 bg-light text-center justify-content-evenly mobileHide">
          <div className="col-12 col-md-5 position-relative">
            <p className="whaleBlueP mb-4">Pricing: $5000+</p>
            <Button
              href="http://book.majestichawaii.com/details/340996/main-deck-floor-charter"
              size="lg"
              className="booknow-cta text-nowrap"
            >
              BOOK NOW
            </Button>
          </div>
          <div className="col-12 col-md-5 position-relative">
            <p className="whaleBlueP mb-4">Pricing: $108 per person</p>
            <Button
              href="http://book.majestichawaii.com/details/339208/group-dinner-cruise"
              size="lg"
              className="booknow-cta text-nowrap"
            >
              BOOK NOW
            </Button>
          </div>
        </div>
        <div
          className="row text-center justify-content-center"
          style={{ backgroundColor: "#415F84" }}
        >
          <div className="col-12 col-md-10 col-lg-8 col-xxl-6 py-5 text-light">
            <h3 className="fw-bold mb-4">QUESTIONS?</h3>
            <p>
              Contact us for customized event information and pricing. Please
              email{" "}
              <a
                className="text-decoration-none text-white"
                href="mailto:groups@atlantisadventures.com"
              >
                groups@atlantisadventures.com
              </a>
            </p>
            <p className="mb-0">
              Or please fill out the form below so one of our team members may
              reach out to you. Mahalo for your interest in Majestic by Atlantis
              Cruises!
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 px-0 d-flex align-items-center">
            <img src={cheers} alt="cheers" className="w-100" />
          </div>
          <div className="col-12 col-md-6 py-5 py-xl-0 d-flex align-items-center px-xl-5 greyBG">
            <div>
              <h3 className="whaleBlueH mb-4">REQUEST INFO</h3>
              <form ref={form} id="chartersForm" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-6">
                    <input
                      className={errorFname}
                      value={fname}
                      name="fname"
                      type="text"
                      placeholder="Full Name"
                      onChange={(e) => {
                        setFname(e.target.value);
                        setErrorFname("formInput");
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <input
                      className={errorEmail}
                      value={email}
                      name="email"
                      type="email"
                      placeholder="Email Address"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setErrorEmail("formInput");
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <input
                      className={errorPhone}
                      style={{ width: "63%", marginRight: "2%" }}
                      value={phone}
                      name="phone"
                      type="text"
                      placeholder="Phone Number"
                      onChange={(e) => {
                        setPhone(e.target.value);
                        setErrorPhone("formInput");
                      }}
                    />
                    <select
                      className={errorPhoneType}
                      style={{
                        position: "relative",
                        width: "35%",
                      }}
                      value={phoneType}
                      name="phoneType"
                      onChange={(e) => {
                        setPhoneType(e.target.value);
                        setErrorPhoneType("formInput");
                      }}
                    >
                      <option value="" disabled>
                        Type
                      </option>
                      <option value="Mobile">Mobile</option>
                      <option value="Home">Work</option>
                      <option value="Work">Home</option>
                    </select>
                  </div>
                  <div className="col-6">
                    <input
                      className={errorCompany}
                      value={company}
                      name="company"
                      type="text"
                      placeholder="Company"
                      onChange={(e) => {
                        setCompany(e.target.value);
                        setErrorCompany("formInput");
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <input
                      className={errorEvent}
                      value={event}
                      name="event"
                      type="text"
                      placeholder="Type of Event"
                      onChange={(e) => {
                        setEvent(e.target.value);
                        setErrorEvent("formInput");
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <input
                      className={errorDate}
                      value={date}
                      name="date"
                      type="date"
                      placeholder="Event Date"
                      onChange={(e) => {
                        setDate(e.target.value);
                        setErrorDate("formInput");
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <div className="d-flex justify-content-evenly">
                      <label className="text-dark" htmlFor="startTime">
                        Start Time
                      </label>
                      <input
                        className={`w-50 ${errorStartTime}`}
                        value={startTime}
                        name="startTime"
                        type="time"
                        id="startTime"
                        placeholder="Start Time"
                        onChange={(e) => {
                          setStartTime(e.target.value);
                          setErrorStartTime("formInput");
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <label className="text-dark" htmlFor="endTime">
                        End Time
                      </label>
                      <input
                        className={`w-50 ${errorEndTime}`}
                        value={endTime}
                        name="endTime"
                        type="time"
                        id="endTime"
                        placeholder="End Time"
                        onChange={(e) => {
                          setEndTime(e.target.value);
                          setErrorEndTime("formInput");
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-7 col-sm-6 col-xl-5">
                    <input
                      className={errorNumPeople}
                      value={numPeople}
                      name="numPeople"
                      type="number"
                      placeholder="# Adults"
                      onChange={(e) => {
                        setNumPeople(e.target.value);
                        setErrorNumPeople("formInput");
                      }}
                    />
                    <input
                      className={errorNumChildren}
                      value={numChildren}
                      name="numChildren"
                      type="number"
                      placeholder="# Children (3-12 yrs)"
                      onChange={(e) => {
                        setNumChildren(e.target.value);
                        setErrorNumChildren("formInput");
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className={errorHear}
                      value={hear}
                      name="hear"
                      placeholder="How did you hear about us?"
                      onChange={(e) => {
                        setHear(e.target.value);
                        setErrorHear("formInput");
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      rows="8"
                      className="formInput"
                      value={info}
                      name="info"
                      placeholder="Additional Info"
                      onChange={(e) => {
                        setInfo(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="text-start">
                  {waitMessage ? (
                    <p className="waitMessage">
                      <em>{waitMessage}</em>
                    </p>
                  ) : (
                    ""
                  )}
                  <Button variant="dark" type="submit">
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row justify-content-evenly bg-light text-center py-5">
          <div className="col-11 col-md-5 relative d-flex align-items-center">
            <div className="mx-auto">
              <h4 className="whaleBlueH">SAMPLE DRINK PACKAGES</h4>
              <a
                className="link whaleBlueP"
                href="Majestic_BeveragePackage.pdf"
              >
                View drink packages
              </a>
              <div className="mb-4 mb-md-0">
                <h4 className="pt-50 whaleBlueH">
                  CHOOSE YOUR FAVORITE CATERER
                </h4>
                <a
                  className="link whaleBlueP"
                  href="Majestic_SampleCateringMenu.pdf"
                >
                  Check out a sample menu
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-5 col-xxl-4 d-flex align-items-center">
            <div className="mx-auto">
              <img src={collage2} alt="collage" className="w-100" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
